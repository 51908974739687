import { dashboardApi } from '../rootApi';

const userDetailsApi = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    userDetails: builder.query({
      query: (userId) => ({
        url: `accounts/${userId}`,
        method: 'GET',
      }),
      providesTags: ['UserDetails'],
    }),
  }),
});

export const { useUserDetailsQuery } = userDetailsApi;
