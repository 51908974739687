import { dashboardApi } from '../rootApi';

const convertDate = (date) => {
  const iso = new Date(date);
  const time = iso.toLocaleTimeString();

  return `${iso.toDateString()}, ${time}`;
};

const auditPageApi = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    // audits querries
    getSessionDetailById: builder.query({
      query: ({ sessionIdFetch }) => ({
        url: `sessionpages/${sessionIdFetch}`,
        method: 'GET',
      }),
      providesTags: ['SessionDetails'],
      transformResponse: (response) => {
        const results = response.answers.map((answer) => ({
          template_field: answer.template_field,
          position_index: answer.position_index,
          data: answer.resourcetype === 'DateTimeFieldData' ? convertDate(answer.data) : answer.data,
          resourcetype: answer.resourcetype,
        }));
        return results.sort((x, y) => x.position_index - y.position_index);
      },
    }),
    getAuditsById: builder.query({
      query: ({ queryParams }) => ({
        url: 'auditdetail',
        method: 'GET',
        params: { audits: queryParams },
      }),
      providesTags: ['AuditsByID'],
      transformResponse: (response) => {
        const results = response.map((audit) => ({
          id: audit.id,
          session_pages: audit.session_pages,
          client: audit.session_pages[0].answers?.find((x) => x.template_field === 'Customer Name'),
          session_id: audit.session_pages[0]?.id || null,
          avatarUrl: `/assets/images/avatars/approved.png`,
          answered_by:
            audit.answered_by !== null
              ? `${audit.answered_by.first_name} ${audit.answered_by.last_name}`
              : 'user not registered',
          uploaded_on: convertDate(audit.uploaded_on),
          color: audit.answered_by ? audit.answered_by.color : '#1ecbe1',
          latitude: audit.latitude,
          longitude: audit.longitude,
          end_time: convertDate(audit.end_time),
          start_time: convertDate(audit.start_time),
        }));
        return results;
      },
      transformErrorResponse: (response) => [],
    }),
  }),
  overrideExisting: false,
});
export const { useGetSessionDetailByIdQuery, useGetAuditsByIdQuery } = auditPageApi;
