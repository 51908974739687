import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = process.env.REACT_APP_API_URL;

export const loginUser = createAsyncThunk('user/Login', async (formData) => axios.post(`${baseurl}/login/`, formData));

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    user: {},
    userAvatar: '/assets/images/avatars/user-icon.png',
    token: '',
    error: false,
  },
  reducers: {
    logoutUser: (state) => {
      state.token = '';
      state.user = {};
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.rejected, (state) => {
        state.error = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.token = action.payload.data.token;
        state.isLoggedIn = true;
        state.error = false;
      });
  },
});

export const { logoutUser } = authSlice.actions;

export default authSlice.reducer;

export const userDetailsSelector = createSelector(
  (state) => state.auth,
  (auth) => auth.user
);

export const loggedInSelector = createSelector(
  (state) => state.auth,
  (auth) => auth.isLoggedIn
);

export const loginErrorSelector = createSelector(
  (state) => state.auth,
  (auth) => auth.error
);

export const authSelector = createSelector(
  (state) => state.auth,
  (auth) => auth.token
);

export const avatarSelector = createSelector(
  (state) => state.auth,
  (auth) => auth.userAvatar
);

export const companyLogoSelector = createSelector(
  (state) => state.auth,
  (auth) => auth.user.organization.company_logo
);
