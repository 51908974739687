import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import './Modal.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useGetSessionDetailByIdQuery } from '../../../redux/services/auditApi';

const AuditModal = ({ setOpenModal, sessionId }) => {
  const [auditDetails, setAuditDetails] = useState([]);

  const { data, isLoading } = useGetSessionDetailByIdQuery({
    sessionIdFetch: sessionId,
  });

  useMemo(() => {
    if (!isLoading) {
      setAuditDetails(data);
    }
  }, [isLoading, data]);

  const textFieldClasses = (field) => {
    if (field === 'Customer Name') {
      return 'capitalizeWords';
    }
    return 'capitalizeLetter';
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="modalHeader">
          <div className="titleCloseBtn">
            <IconButton
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <CloseIcon color="error" />
            </IconButton>
          </div>
          <div className="title">
            <h1>Audit Details</h1>
          </div>
        </div>
        <div className="body">
          <Grid container spacing={1} direction="column">
            {auditDetails.map((audit) => {
              if (audit.resourcetype === 'ImageFieldData') {
                return (
                  <Grid item sm={12} md={12} direction="column" display="flex" mb={1} key={audit.id}>
                    <Typography variant="overline">{audit.template_field}</Typography>
                    {!audit.data || audit.data === '' ? (
                      <Typography variant="body" style={{ textAlign: 'center' }}>
                        No Image captured
                      </Typography>
                    ) : (
                      <img src={audit.data} style={{ minHeight: '450px', width: '100%' }} alt={audit.template_field} />
                    )}
                  </Grid>
                );
              }
              if (audit.resourcetype === 'LocationFieldData') {
                if (audit.data && audit.data !== '') {
                  if (audit.data.includes('(')) {
                    let locationString = audit.data.replace('(', '');
                    locationString = locationString.replace(')', '');
                    const listLocation = locationString.split(',');
                    console.log(listLocation);

                    return (
                      <Grid
                        item
                        sm={12}
                        md={12}
                        justifyContent="center"
                        display="flex"
                        direction="column"
                        key={audit.id}
                      >
                        <Typography variant="overline" mb={1}>
                          {audit.template_field}
                        </Typography>
                        <MapContainer
                          center={[listLocation[1], listLocation[3]]}
                          zoom={13}
                          style={{ height: '450px', width: '90%', alignSelf: 'center' }}
                          scrollWheelZoom={false}
                        >
                          <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={[listLocation[1], listLocation[3]]}>
                            <Popup>
                              <h3>Session Details</h3>
                              <ul>
                                <li>
                                  <span style={{ fontWeight: '700' }}>Customer:</span>{' '}
                                  {auditDetails?.find((x) => x.template_field === 'Customer Name').data}
                                </li>
                                <li>
                                  <span style={{ fontWeight: '700' }}>Start Time: </span>
                                  {new Date(
                                    auditDetails?.find((x) => x.template_field === 'Session start time').data
                                  ).toLocaleString()}
                                </li>
                              </ul>
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </Grid>
                    );
                  }
                }
                return (
                  <Grid item sm={12} md={12} justifyContent="center" display="flex" direction="column" key={audit.id}>
                    <Typography variant="overline" mb={1}>
                      {audit.template_field}
                    </Typography>
                    <MapContainer
                      center={[-28.4792625, 24.6727135]}
                      zoom={5}
                      style={{ height: '450px', width: '90%', alignSelf: 'center' }}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </MapContainer>
                  </Grid>
                );
              }
              if (audit.resourcetype === 'BooleanFieldData') {
                return (
                  <Grid item sm={12} md={12} mb={2} direction="column" display="flex" key={audit.id}>
                    <Typography variant="overline" mb={1}>
                      {audit.template_field}
                    </Typography>
                    <Typography variant="body">{audit.data ? audit.data : 'False'}</Typography>
                  </Grid>
                );
              }
              return (
                <Grid item sm={12} md={12} mb={2} direction="column" display="flex" key={audit.id}>
                  <Typography variant="overline" mb={1}>
                    {audit.template_field}
                  </Typography>
                  <Typography variant="body1" className={textFieldClasses(audit.template_field)}>
                    {audit.data ? audit.data : 'No answer received'}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};
AuditModal.propTypes = {
  setOpenModal: PropTypes.func,
  sessionId: PropTypes.number,
};
export default AuditModal;
