import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './reducers/userSlice';
import teamReducer from './reducers/teamSlice';
import authReducer from './reducers/authSlice';
import auditReducer from './reducers/auditReducer';
import { dashboardApi } from './rootApi';

const generatePersistConfig = (storageKey) => {
  const persistConfig = {
    key: storageKey,
    // eslint-disable-next-line
    storage: storage,
    blacklist: [dashboardApi.reducerPath],
  };
  return persistConfig;
};

export const rootReducer = combineReducers({
  users: persistReducer(generatePersistConfig('users'), userReducer),
  teams: persistReducer(generatePersistConfig('teams'), teamReducer),
  auth: persistReducer(generatePersistConfig('auth'), authReducer),
  audits: persistReducer(generatePersistConfig('audits'), auditReducer),
  [dashboardApi.reducerPath]: dashboardApi.reducer,
});
