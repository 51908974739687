import { dashboardApi } from '../rootApi';

const summaryPageApi = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    widgetSummary: builder.query({
      query: () => ({
        url: 'summary',
        method: 'GET',
      }),
      providesTags: ['Widgets'],
    }),
    teamAuditsSummary: builder.query({
      query: ({ queryParams }) => ({
        url: 'team-summary',
        method: 'GET',
        params: queryParams,
      }),
      providesTags: ['TeamSummary'],
      transformResponse: (response) => response.audits_per_user,
    }),
  }),
});

export const { useTeamAuditsSummaryQuery, useWidgetSummaryQuery } = summaryPageApi;
