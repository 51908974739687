import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = process.env.REACT_APP_API_URL;

export const fetchListAudits = createAsyncThunk('audits/List', async (query) =>
  axios.get(`${baseurl}/auditids`, {
    headers: {
      Authorization: `Token ${query.token}`,
      'Content-Type': 'application/json',
    },
    params: query.queryParams,
  })
);
export const subtractMonths = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 2);
  return date.toLocaleDateString('en-GB').split('/').reverse().join('-');
};

export const auditSlice = createSlice({
  name: 'audits',
  initialState: {
    audits: [],
    filteredAudits: [],
    availableDates: [],
    totalCount: 0,
    pageNumber: 0,
    rowsPerPage: 5,
    synch: '',
    filters: {
      dateSingle: '',
      dateTo: new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'),
      dateFrom: subtractMonths(),
      userSelected: '',
    },
  },
  reducers: {
    recordsPerPageChange: (state, action) => {
      const copyList = state.audits.slice(0, action.payload);
      return { ...state, rowsPerPage: action.payload, filteredAudits: copyList };
    },
    pageChange: (state, action) => {
      const copyList = state.audits.slice(
        state.rowsPerPage * action.payload,
        action.payload * state.rowsPerPage + state.rowsPerPage
      );
      return { ...state, pageNumber: action.payload, filteredAudits: copyList };
    },
    selectUser: (state, action) => ({ ...state, filters: { ...state.filters, userSelected: action.payload } }),
    selectDate: (state, action) => ({ ...state, filters: { ...state.filters, dateSingle: action.payload } }),
    selectStartDate: (state, action) => ({ ...state, filters: { ...state.filters, dateFrom: action.payload } }),
    selectEndDate: (state, action) => ({ ...state, filters: { ...state.filters, dateTo: action.payload } }),
    resetFilters: (state, action) => ({
      ...state,
      pageNumber: action.payload.page,
      rowsPerPage: action.payload.rows,
      filters: {
        ...state.filters,
        userSelected: '',
        dateSingle: '',
        dateFrom: subtractMonths(),
        dateTo: new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'),
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListAudits.fulfilled, (state, action) => {
      const listIds = action.payload.data.audits.map((x) => x.id);
      const listDates = action.payload.data.audits.map((x) => x.uploaded_on);
      state.availableDates = listDates;
      state.audits = listIds;
      state.totalCount = action.payload.data.total;
      state.synch = action.payload.data.synched;
      state.filteredAudits = listIds.slice(0, state.rowsPerPage);
    });
  },
});

export const {
  recordsPerPageChange,
  pageChange,
  resetFilters,
  selectDate,
  selectEndDate,
  selectStartDate,
  selectUser,
} = auditSlice.actions;

export default auditSlice.reducer;

export const pageNumberSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.pageNumber
);

export const rowsPerPageSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.rowsPerPage
);

export const totalRowsSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.totalCount
);

export const filteredAuditSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.filteredAudits
);

export const availableDatesSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.availableDates
);

export const synchSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.synch
);

export const allAuditIdSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.audits
);

export const auditFilterSelector = createSelector(
  (state) => state.audits,
  (audits) => audits.filters
);
