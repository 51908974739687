import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const LoadingContext = createContext({
  loading: true,
  setLoading: null,
});

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const value = { loading, setLoading };
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('Loading must be used within LoadingProvider');
  }
  return context;
}

export { LoadingProvider, useLoading };

LoadingProvider.propTypes = {
  children: PropTypes.any,
};
