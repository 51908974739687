import { useSelector } from 'react-redux';
import { companyLogoSelector } from '../../../redux/reducers/authSlice';

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export default function LanguagePopover() {
  const companyLogo = useSelector(companyLogoSelector);

  return (
    <div style={{ maxHeight: '40px', position: 'relative' }}>
      {companyLogo && companyLogo !== '' && (
        <img src={`${MEDIA_URL}${companyLogo}`} alt="company Logo" style={{ maxHeight: '40px', width: 'auto' }} />
      )}
    </div>
  );
}
