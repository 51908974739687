import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../../components/logo';
import { LoadingProvider } from '../../Loader-Context';
import LoadingApi from '../../components/loading/LoadingApi';

import { authSelector } from '../../redux/reducers/authSlice';
import { fetchListAudits } from '../../redux/reducers/auditReducer';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const authToken = useSelector(authSelector);
  const dispatch = useDispatch();
  dispatch(fetchListAudits({ token: authToken, queryParams: {} }));
  return (
    <>
      <StyledHeader>
        <Logo />
      </StyledHeader>

      <LoadingProvider>
        <LoadingApi />
        <Outlet />
      </LoadingProvider>
    </>
  );
}
