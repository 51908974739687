import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import DashboardAppPage from './pages/DashboardAppPage';
import UserCreate from './pages/UserCreate';
import AuditPageCopy from './pages/AuditPageCopy';
import TMDPageCopy from './pages/TMDPageCopy';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'tmd', element: <TMDPageCopy /> },
        { path: 'create-user', element: <UserCreate /> },
        { path: 'audits', element: <AuditPageCopy /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [{ element: <Navigate to="/dashboard/app" />, index: true }],
    },
  ]);

  return routes;
}
