import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../components/logo';
import { loginErrorSelector, loginUser } from '../redux/reducers/authSlice';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        SSI Capture
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const LoginForm = ({ submitForm, errorMessage }) => (
  <Box
    component="form"
    onSubmit={submitForm}
    noValidate
    sx={{ mt: 1 }}
    style={{ display: 'flex', flexDirection: 'column', minWidth: '350px' }}
  >
    {errorMessage ? (
      <Typography variant="body" style={{ color: 'red', textAlign: 'center' }}>
        Incorrect details, verify your details and try again.
      </Typography>
    ) : null}
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      autoFocus
    />
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="password"
      autoComplete="current-password"
    />
    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
      Sign In
    </Button>
  </Box>
);

LoginForm.propTypes = {
  submitForm: PropTypes.func,
  errorMessage: PropTypes.bool,
};

const theme = createTheme();

export default function SignIn() {
  const [loading, setLoading] = React.useState(false);
  const error = useSelector(loginErrorSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(false);
  }, [error]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    dispatch(loginUser({ username: data.get('email'), password: data.get('password') }));
  };

  const loginVerify = React.useMemo(() => {
    if (loading) return <CircularProgress style={{ marginTop: '50px' }} size={100} />;
    return <LoginForm submitForm={handleSubmit} errorMessage={error} />;
  }, [loading, error, handleSubmit]);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', justifyContent: 'center' }}>
            <Logo />
          </Box>

          <Typography component="h1" variant="h5">
            {loading ? 'Signing in' : 'Sign in'}
          </Typography>
          {loginVerify}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
