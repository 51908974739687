import { Calendar } from 'react-calendar';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import '../../../theme/Styles.scss';
import './Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
// @mui
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Card, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// @eslint-ignore
import FilterListIcon from '@mui/icons-material/FilterList';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GenerateExcelFile, GenerateExcelFileByIds } from '../../../utils/APIFunctions';

import { authSelector } from '../../../redux/reducers/authSlice';
import {
  allAuditIdSelector,
  resetFilters,
  selectDate,
  selectEndDate,
  selectStartDate,
  selectUser,
} from '../../../redux/reducers/auditReducer';

const AuditFilter = ({
  endDateValue,
  startDateValue,
  ApplyFilter,
  singleDateValue,
  componentView,
  ResetFilters,
  selectedUser,
  listUsers,
  availableDates,
}) => {
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const [datesAvailable, setDatesAvailable] = useState([]);
  const authToken = useSelector(authSelector);
  const listIds = useSelector(allAuditIdSelector);

  useEffect(() => {
    const convertedDates = availableDates.map((date) => dateConverter(date));
    setDatesAvailable(convertedDates);
  }, [availableDates]);

  const dateConverter = (date) => {
    const currentDate = new Date(date).toLocaleString().split(',')[0];
    return currentDate;
  };

  const ExportXLSX = async () => {
    let response;
    if (componentView === 'test') {
      response = await GenerateExcelFile(singleDateValue, startDateValue, endDateValue, selectedUser, authToken);
    } else {
      response = await GenerateExcelFileByIds(listIds, authToken);
    }

    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute(
      'download',
      `audits_${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}_${new Date().getHours()}-${new Date().getMinutes()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const ResetFields = () => {
    dispatch(resetFilters({ page: 0, rows: 5 }));
    setDate(new Date());
  };

  const tileClassNames = ({ date }) => {
    const currentDate = dateConverter(date);

    if (datesAvailable.length > 0) {
      if (datesAvailable.includes(currentDate)) {
        return 'dateAvailable';
      }
    }
    return null;
  };

  return (
    <Card sx={{ padding: '20px', marginBottom: '30px' }}>
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item xs={12} md={4} direction="column" display="flex" alignItems="center">
          <Calendar
            value={date}
            onChange={(e) => {
              dispatch(selectEndDate(''));
              dispatch(selectStartDate(''));
              setDate(new Date(e));
              dispatch(selectDate(new Date(e).toLocaleDateString('en-GB').split('/').reverse().join('-')));
            }}
            tileClassName={tileClassNames}
          />
          <Button
            variant="contained"
            color="primary"
            
            sx={{ m: 1, maxWidth: '300px', width: '100%' ,marginTop:"50px"}}
            endIcon={<FileDownloadIcon />}
            onClick={() => {
              ExportXLSX();
            }}
            target="_blank"
            download
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={6} container direction="column">
          <FormControl sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">User</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedUser}
              label="User"
              onChange={(e) => {
                dispatch(selectUser(`${e.target.value}`));
              }}
            >
              <MenuItem value="">No User</MenuItem>
              {listUsers?.map((user) => (
                <MenuItem value={user.id} key={user.id}>
                  {user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container spacing={3} direction="row" alignItems="baseline" mb={2} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <FormControl sx={{ m: 1, minWidth: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} m={1}>
                  <DatePicker
                    disableFuture
                    label="Start Date"
                    value={startDateValue}
                    onChange={(newValue) => {
                      setDate(new Date());
                      dispatch(
                        selectStartDate(new Date(newValue).toLocaleDateString('en-GB').split('/').reverse().join('-'))
                      );
                      dispatch(selectDate(''));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ m: 1, minWidth: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} m={1}>
                  <DatePicker
                    disableFuture
                    label="End Date"
                    value={endDateValue}
                    onChange={(newValue) => {
                      setDate(new Date());
                      dispatch(
                        selectEndDate(new Date(newValue).toLocaleDateString('en-GB').split('/').reverse().join('-'))
                      );
                      dispatch(selectDate(''));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} justifyContent="center" display="flex">
            <Button
              variant="contained"
              color="primary"
              sx={{ m: 1 }}
              endIcon={<FilterListIcon />}
              onClick={() => {
                ApplyFilter();
              }}
            >
              Apply Filter
            </Button>

            <Button
              variant="contained"
              color="error"
              sx={{ m: 1 }}
              endIcon={<RotateLeftIcon />}
              onClick={() => {
                ResetFields();
                ResetFilters();
              }}
            >
              Reset Filter
            </Button>
          </Grid>
          {/* <Typography variant="body" style={{ textAlign: 'center' }}>
          Last Synced:{' '}
          <Typography variant="overline" style={{ color: lastRun ? 'green' : 'red' }}>
            {lastRun ?? 'Never'}
          </Typography>
        </Typography> */}
        </Grid>
      </Grid>
    </Card>
  );
};
AuditFilter.propTypes = {
  endDateValue: PropTypes.string,
  startDateValue: PropTypes.string,
  ApplyFilter: PropTypes.func,
  singleDateValue: PropTypes.string,
  listUsers: PropTypes.array,
  lastRun: PropTypes.any,
  selectedUser: PropTypes.string,
  availableDates: PropTypes.array,
  ResetFilters: PropTypes.func,
  componentView: PropTypes.string,
};
export default AuditFilter;
