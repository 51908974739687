import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, CircularProgress, Typography } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.element,
  chartColors: PropTypes.array,
  loading: PropTypes.bool,
};
// eslint-disable-next-line
export default function AppWebsiteVisits({
  title,
  subheader,
  filter,
  chartLabels,
  chartColors,
  chartData,
  loading,
  ...other
}) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '10%', barHeight: '85%' } }, // distributed: true,
    fill: { type: chartData.map((i) => i.fill) },
    // colors: chartColors,
    labels: chartLabels,
    xaxis: { type: 'string' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} Sessions`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      {filter}
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {loading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <CircularProgress size={150} />
            <Typography variant="body">Loading Data..</Typography>
          </div>
        ) : (
          <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
        )}
      </Box>
    </Card>
  );
}
