// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 30, height: 30 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_users'),
  },
  {
    title: 'audits',
    path: '/dashboard/audits',
    icon: icon('ic_note'),
  },
  {
    title: 'track my day',
    path: '/dashboard/tmd',
    icon: icon('ic_gps'),
  },
];

export default navConfig;
