import axios from 'axios';

const baseurl = process.env.REACT_APP_API_URL;

const convertDate = (date) => {
  const iso = new Date(date);
  const time = iso.toLocaleTimeString();

  return `${iso.toDateString()}, ${time}`;
};

export const GenerateExcelFile = async (date, dateFrom, dateTo, user, token) =>
  axios.get(
    `${baseurl}/export?date=${date === null ? '' : date}&date_from=${dateFrom === null ? '' : dateFrom}&date_to=${
      dateTo === null ? '' : dateTo
    }&user=${user === null ? '' : user}`,
    {
      responseType: 'blob',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/ms-excel',
      },
    }
  );

export const GenerateExcelFileByIds = async (auditIds, token) =>
  axios.get(`${baseurl}/export-ids?audits=${auditIds}`, {
    responseType: 'blob',
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/ms-excel',
    },
  });

export const GetListUsers = async (token) => {
  const response = await axios.get(`${baseurl}/accounts/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  const results = response.data.map((user) => ({
    id: user.id,
    username: user.username,
    email: user.email,
    avatarUrl: `/assets/images/avatars/approved.png`,
    first_name: user.first_name,
    last_name: user.last_name,
    organization: user.organization.organization_name,
    team: user.team,
    phone_number: user.phone_number,
    color: user.color,
  }));
  return results;
};

export const GetTemplates = async (token) => {
  const response = await axios.get(`${baseurl}/templates/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
};

export const GetReports = async (token, id) => {
  const response = await axios.get(`${baseurl}/reporting?template_id=${id}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const getAnswersList = (audit) => {
    const listPageAnswers = [];
    audit.session_pages.forEach((page) => {
      page.answers.forEach((answer) => listPageAnswers.push(answer));
    });
    return listPageAnswers.sort((a, b) => a.position_index - b.position_index);
  };

  const results = response.data.map((audit) => ({
    id: audit.id,
    template: audit.template,
    answered_by: audit.answered_by !== null ? audit.answered_by.username : 'user not registered',
    uploaded_on: convertDate(audit.uploaded_on),
    answers: getAnswersList(audit),
  }));
  return results;
};

export const ListTeams = async (userToken) => {
  const response = await axios.get(`${baseurl}/teams`, {
    headers: {
      Authorization: `Token ${userToken}`,
    },
  });
  return response.data;
};
export const CreateUser = async ({ data, userToken }) => {
  const response = await axios.post(`${baseurl}/accounts/`, data, {
    headers: {
      Authorization: `Token ${userToken}`,
    },
  });
  return response;
};

export const fetchUserByID = async (id, userToken) => {
  const response = await axios.get(`${baseurl}/accounts/${id}`, {
    headers: {
      Authorization: `Token ${userToken}`,
    },
  });
  return response.data;
};
