import { dashboardApi } from '../rootApi';

const tmdPageApi = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    tmdFirebase: builder.query({
      query: (userSelected) => ({
        url: 'firebase-tmd',
        params: { user: userSelected },
      }),
      providesTags: ['TmdDates'],
    }),
    tmdFireBaseDetails: builder.query({
      query: (queryParams) => ({
        url: 'firebase-tmd-details',
        params: { date: queryParams.date, user: queryParams.user },
      }),
      providesTags: ['TmdDetails'],
    }),
  }),
});

export const {
  useLazyTmdFirebaseQuery,
  useLazyTmdFireBaseDetailsQuery,
} = tmdPageApi;
