import React from 'react';
import { useSelector } from 'react-redux';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import LoginPage from './pages/LoginPage';
import { loggedInSelector } from './redux/reducers/authSlice';

// ----------------------------------------------------------------------

export default function App() {
  const isLoggedIn = useSelector(loggedInSelector);
  if (!isLoggedIn) return <LoginPage />;
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
