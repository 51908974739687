import {
  Container,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Card,
  CircularProgress,
} from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Calendar } from 'react-calendar';
import { Helmet } from 'react-helmet-async';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import 'leaflet-routing-machine';
import './Tmd.css';

import { usersListSelector } from '../redux/reducers/userSlice';
import { useLoading } from '../Loader-Context';
import {
  useLazyTmdFirebaseQuery,
  useLazyTmdFireBaseDetailsQuery,
} from '../redux/services/tmdApi';

const dateConverter = (date) => {
  const currentDate = new Date(date).toLocaleString().split(',')[0];
  return currentDate;
};

const MapReset = () => {
  const context = useLeafletContext();
  context.map.setView([-28.4792625, 24.6727135], 6);
  context.map.eachLayer((layer) => {
    if (layer._url) return;
    layer.remove();
  });
};

const RouteLine = ({ map, tmdData, locationError }) => {
  const context = useLeafletContext();
  const listCoordinates = [];
  const markers = [];

  context.map.setView([-28.4792625, 24.6727135], 6);
  context.map.eachLayer((layer) => {
    if (layer._url) return;
    layer.remove();
  });

  if (map && tmdData) {
    const { locations } = tmdData;
    if (locations.length > 0) {
      locations.forEach((location) => {
        listCoordinates.push([location.lat, location.long]);
        markers.push(L.latLng([location.lat, location.long]));
      });
      context.map.addLayer(L.polyline(markers, { color: '#3f00ff', opacity: 0.5, weight: 5 }));
      for (let i = 0; i < listCoordinates.length; i += 1) {
        if (i === 0 || i === listCoordinates.length - 1) {
          let color = '#FF0000';
          if (i === 0) {
            color = '#00FF00';
          }
          const urlIcon = `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px" viewBox="0 0 72 72" id="emoji">
                                  <g id="color">
                                    <path fill="${color}" d="M52.5733,29.11c0-9.3149-7.1338-16.8923-15.9034-16.8923S20.7665,19.795,20.7665,29.114c.0027.4653.2238,11.6088,12.9612,31.2452a3.4626,3.4626,0,0,0,2.8174,1.9343c1.8394,0,3.0941-2.0266,3.2162-2.2327C52.58,40.4135,52.58,29.5534,52.5733,29.11ZM36.67,35.9135a7.0826,7.0826,0,1,1,7.0826-7.0826A7.0907,7.0907,0,0,1,36.67,35.9135Z"/>
                                    <path fill="${color}" d="M52.5733,29.11c0-9.3149-7.1338-16.8923-15.9034-16.8923a14.96,14.96,0,0,0-3.8645.5254c8.3948.4493,15.0989,7.8225,15.0989,16.849.0068.4436.0068,11.3036-12.812,30.9509a5.8543,5.8543,0,0,1-.5865.7972,2.8549,2.8549,0,0,0,2.0393.9534c1.8394,0,3.0941-2.0266,3.2162-2.2327C52.58,40.4135,52.58,29.5534,52.5733,29.11Z"/>
                                  </g>
                                  <g id="line">
                                    <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M36.5451,62.2935a3.4628,3.4628,0,0,1-2.8174-1.9343C20.99,40.7228,20.7692,29.5793,20.7665,29.114c0-9.3191,7.1337-16.8964,15.9034-16.8964S52.5733,19.7949,52.5733,29.11c.0068.4436.0068,11.3036-12.812,30.9509C39.6392,60.2669,38.3845,62.2935,36.5451,62.2935Z"/>
                                    <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M36.67,35.9135a7.0826,7.0826,0,1,1,7.0827-7.0826A7.0906,7.0906,0,0,1,36.67,35.9135Z"/>
                                  </g>
                                </svg>
              `)}`;
          const customMarkerIcon = L.icon({
            popupAnchor: [-0, -0],
            iconSize: [60, 60],
            iconUrl: urlIcon,
          });
          context.map.addLayer(
            L.marker(markers[i], {
              clickable: true,
              icon: customMarkerIcon,
            }).bindPopup(
              L.popup({ maxWidth: 500 }).setContent(
                `<p style="font-size:'16px'"> ${i===0?"Start of Day":"End of Day"}: ${locations[i].timestamp}</p>`
              )
            )
          );
        }
      }
      context.map.setView(listCoordinates[0], 11);
    } else {
      locationError(true);
    }
  } else {
    context.map.setView([-28.4792625, 24.6727135], 6);
    context.map.eachLayer((layer) => {
      if (layer._url) return;
      layer.remove();
    });
  }
};

const TMDPageCopy = () => {
  const [date, setDate] = useState(new Date());
  const [queryId, setQueryId] = useState('');
  const [selectedUser, setUser] = useState('');
  const users = useSelector(usersListSelector);

  const [trigger, { data: tmdFetched, isLoading: loadingTmd, isFetching: fetchingTmd }] =
    useLazyTmdFireBaseDetailsQuery();
  const { setLoading } = useLoading();
  const [error, setError] = useState(false);

  const [fetchFirebase, { data: fireBaseFetched, isLoading: fetchingFirebase, isFetching: refetchingFirebase }] =
    useLazyTmdFirebaseQuery();

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (loadingTmd || fetchingTmd) setLoading(true);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTmd, fetchingTmd]);

  const tmdList = useMemo(() => {
    if (fireBaseFetched && !fetchingFirebase && !refetchingFirebase) {
      const dates = fireBaseFetched.dates_available.map((x) => dateConverter(x));
      return { datesAvailable: dates };
    }
    return { datesAvailable: [] };
  }, [fireBaseFetched, fetchingFirebase, refetchingFirebase]);

  const tmd = useMemo(() => {
    if (tmdFetched) {
      return tmdFetched;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmdFetched]);

  const handleUpdateTMD = () => {
    if (queryId === '') {
      setError(true);
      return;
    }
    setError(false);
    const queryParams = { user: selectedUser, date: queryId };
    trigger(queryParams, true);
  };

  const tileClassnames = ({ date }) => {
    const currentDate = dateConverter(date);
    if (tmdList.datesAvailable.length > 0) {
      if (tmdList.datesAvailable.includes(currentDate)) {
        return 'dateAvailable';
      }
    }
    return null;
  };

  const errorReset = useMemo(() => {
    if (error) return <MapReset />;
    return null;
  }, [error]);

  const memoRoute = useMemo(() => {
    if (!loadingTmd && tmd && !fetchingTmd) return <RouteLine map={map} tmdData={tmd} locationError={setError} />;
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmd, loadingTmd, fetchingTmd]);

  return (
    <div>
      <Helmet>
        <title>Track My Day</title>
      </Helmet>

      <Container maxWidth="xl" style={{ padding: 0 }}>
        <Grid container spacing={1} direction="row" wrap="wrap">
          <Grid item md={3} lg={4} sm={12}>
            <Card sx={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
              <Typography variant="h4">Track User Day</Typography>
              <FormControl sx={{ m: 1, minWidth: '90%' }}>
                <InputLabel id="user-select">User</InputLabel>
                <Select
                  labelId="user-select"
                  id="demo-simple-select-helper"
                  value={selectedUser}
                  label="User"
                  onChange={(e) => {
                    setUser(e.target.value);
                    fetchFirebase(e.target.value, true);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {users?.map((user) => (
                    <MenuItem value={user.id} key={user.id}>
                      {user.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ alignSelf: 'center' }}>
                <Calendar
                  value={date}
                  onChange={(e) => {
                    setDate(new Date(e));
                    const date = dateConverter(new Date(e));
                    const qDate = new Date(e).toLocaleDateString('sv-SE');
                    setQueryId(tmdList.datesAvailable.find((x) => x === date) ? qDate : '');
                  }}
                  tileClassName={tileClassnames}
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                sx={{ m: 1, width: 'fit-content', alignSelf: 'center', minWidth: '150px' }}
                endIcon={fetchingFirebase || refetchingFirebase || loadingTmd || fetchingTmd ? null : <GpsFixedIcon />}
                onClick={handleUpdateTMD}
                disabled={fetchingFirebase || refetchingFirebase || loadingTmd || fetchingTmd }
              >
                {fetchingFirebase || refetchingFirebase || loadingTmd || fetchingTmd ? (
                  <CircularProgress size={20} color="info" />
                ) : (
                  "Track User's Day"
                )}
              </Button>
              {error ? (
                <Typography variant="body" style={{ color: 'red', textAlign: 'center' }}>
                  No locations recorded for the date selected.
                </Typography>
              ) : null}
            </Card>
          </Grid>
          <Grid item md={9} lg={8} sm={12}>
            <Card sx={{ padding: '10px' }}>
              <MapContainer
                zoom={6}
                style={{ height: '700px', width: '100%', borderRadius: '12px' }}
                center={[-28.4792625, 24.6727135]}
                whenReady={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {memoRoute}
                {errorReset}
                {/* <TMDRoute/> */}
              </MapContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TMDPageCopy;
