import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// components

// sections
import { AppWebsiteVisits, AppWidgetSummary } from '../sections/@dashboard/app';
import { fetchUsersList } from '../redux/reducers/userSlice';
import { fetchListTeams, teamsListSelector } from '../redux/reducers/teamSlice';
import { useLoading } from '../Loader-Context';
import { useTeamAuditsSummaryQuery, useWidgetSummaryQuery } from '../redux/services/summaryApi';
import { authSelector } from '../redux/reducers/authSlice';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const [selectedTeam, setSelectedTeam] = useState('');
  const teams = useSelector(teamsListSelector);
  const { setLoading } = useLoading();
  const authToken = useSelector(authSelector);
  const queryString = {
    queryParams: { team: selectedTeam },
  };
  if (selectedTeam === '') queryString.queryParams = {};
  const { data: fetchedTeamSummary, isLoading: loadingSummaryData } = useTeamAuditsSummaryQuery(queryString);

  const { data: fetchedWidgetSummary, isLoading: loadingWidgetData } = useWidgetSummaryQuery();

  const filterUserGraph = useMemo(() => {
    if (fetchedTeamSummary) {
      return fetchedTeamSummary;
    }
    return [];
  }, [fetchedTeamSummary]);

  const summary = useMemo(() => {
    if (fetchedWidgetSummary) {
      return {
        active_users: fetchedWidgetSummary.active_users,
        total_audits: fetchedWidgetSummary.total_audits,
        audits_per_week: fetchedWidgetSummary.audits_per_week,
      };
    }
    return {
      active_users: 0,
      total_audits: 0,
      audits_per_week: 0,
    };
  }, [fetchedWidgetSummary]);

  const GetData = async () => {
    dispatch(fetchUsersList(authToken));
    dispatch(fetchListTeams(authToken));
    setLoading(false);
  };

  useEffect(() => {
    GetData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Active Users"
              total={summary ? summary.active_users : 0}
              icon={'ant-design:user-outlined'}
              loading={loadingWidgetData}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Sessions"
              total={summary ? summary.total_audits : 0}
              color="info"
              icon={'ant-design:file-done-outlined'}
              loading={loadingWidgetData}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Sessions this Week"
              total={summary ? summary?.audits_per_week : 0}
              color="warning"
              icon={'ant-design:file-add-outlined'}
              loading={loadingWidgetData}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits
              title="Total Completed Sessions"
              subheader="per user registered"
              loading={loadingSummaryData}
              filter={
                <FormControl sx={{ m: 1, minWidth: '40%' }}>
                  <InputLabel id="demo-simple-select-helper-label">Team</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedTeam}
                    label="Team"
                    onChange={(e) => {
                      setSelectedTeam(e.target.value);
                    }}
                  >
                    <MenuItem value={''} key={'reset'}>
                      {' '}
                      Reset Filter
                    </MenuItem>
                    {teams?.map((team) => (
                      <MenuItem value={team.id} key={team.id}>
                        {team.team_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              chartLabels={filterUserGraph?.map((x) => x.name)}
              chartData={[
                {
                  type: 'bar',
                  fill: 'solid',
                  data: filterUserGraph?.map((x) => x.total),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
