import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import '../theme/Styles.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
// @mui
import {
  Card,
  Table,
  Stack,
  Avatar,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import L from 'leaflet';
// sections
import { useLeafletContext } from '@react-leaflet/core';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useLoading } from '../Loader-Context';
import { AuditListHead } from '../sections/@dashboard/audit';
import AuditModal from '../sections/@dashboard/audit/AuditModal';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import './Map.css';
import { useGetAuditsByIdQuery } from '../redux/services/auditApi';
import { usersListSelector } from '../redux/reducers/userSlice';
import {
  pageNumberSelector,
  rowsPerPageSelector,
  totalRowsSelector,
  filteredAuditSelector,
  fetchListAudits,
  pageChange,
  recordsPerPageChange,
  resetFilters,
  availableDatesSelector,
  synchSelector,
  auditFilterSelector,
  subtractMonths,
} from '../redux/reducers/auditReducer';
import { authSelector } from '../redux/reducers/authSlice';
import AuditFilter from '../sections/@dashboard/audit/AuditFliter';

const TABLE_HEAD = [
  { id: 'client ', label: 'Client', alignRight: false }, // this should be the template name not the template id - How do I change that? TODO
  { id: 'answered_by', label: 'Answered By', alignRight: false },
  { id: 'uploaded_on', label: 'Uploaded On ', alignRight: false },
  { id: 'details', label: 'View Details', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const Legend = ({ map, users, selectedUser }) => {
  const context = useLeafletContext();
  useEffect(() => {
    if (map && users) {
      if (document.getElementById('map-legend')) document.getElementById('map-legend').remove();
      const legend = L.control({ position: 'bottomleft', id: 'map-legend' });
      const labels = [];
      if (selectedUser === '') {
        users?.forEach((user) => {
          labels.push(`<i style="background:${user.color}"></i> ${user.first_name} ${user.last_name}`);
        });
      } else {
        const user = users?.find((x) => x.id === parseInt(selectedUser, 10));
        labels.push(`<i style="background:${user?.color}"></i> ${user?.first_name} ${user?.last_name}`);
      }

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        div.id = 'map-legend';
        div.innerHTML = `<h6 style='font-weight:800;'>Map Keys:</h6>${labels.join('<br/>')}`;
        return div;
      };
      context.map.addControl(legend);
      // legend.addTo(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, users, selectedUser]);
  return null;
};
Legend.propTypes = {
  map: PropTypes.any,
  users: PropTypes.array,
  selectedUser: PropTypes.string,
};

const AuditPageCopy = () => {
  // redux states
  const dispatch = useDispatch();
  const page = useSelector(pageNumberSelector);
  const rowsPerPage = useSelector(rowsPerPageSelector);
  const rowCountTotal = useSelector(totalRowsSelector);
  const authToken = useSelector(authSelector);
  const queryAudits = useSelector(filteredAuditSelector);
  const datesAvailable = useSelector(availableDatesSelector);
  const lastRun = useSelector(synchSelector);
  const { dateSingle, dateTo, dateFrom, userSelected } = useSelector(auditFilterSelector);

  //  MUI components
  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [editModal, setEditModal] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const listUsers = useSelector(usersListSelector);
  const [map, setMap] = useState(null);
  const { setLoading } = useLoading();

  const queryString = {
    token: authToken,
    queryParams: {
      date: dateSingle,
      date_from: dateFrom,
      date_to: dateTo,
      user: userSelected,
    },
  };

  const {
    data: fetchedAuditData,
    isFetching: refetchingAudits,
    isLoading: isLoadingAudits,
  } = useGetAuditsByIdQuery({ queryParams: queryAudits });

  const auditData = useMemo(() => {
    if (fetchedAuditData) {
      return { allAuditData: fetchedAuditData };
    }
    return { allAuditData: [] };
  }, [fetchedAuditData]);

  useEffect(() => {
    setLoading(isLoadingAudits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAudits]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLoading(refetchingAudits), [refetchingAudits]);

  const ResetAudits = () => {
    dispatch(
      fetchListAudits({
        token: authToken,
        queryParams: {
          date: '',
          date_from: subtractMonths(),
          date_to: new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'),
          user: '',
        },
      })
    );
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(pageChange(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(pageChange(0));
    dispatch(recordsPerPageChange(parseInt(event.target.value, 10)));
  };

  const filteredAudits = applySortFilter(auditData.allAuditData, getComparator(order, orderBy));

  const GetCustomMarker = (colour) => {
    const urlIcon = `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
                        <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px" viewBox="0 0 72 72" id="emoji">
                          <g id="color">
                            <path fill="${colour}" d="M52.5733,29.11c0-9.3149-7.1338-16.8923-15.9034-16.8923S20.7665,19.795,20.7665,29.114c.0027.4653.2238,11.6088,12.9612,31.2452a3.4626,3.4626,0,0,0,2.8174,1.9343c1.8394,0,3.0941-2.0266,3.2162-2.2327C52.58,40.4135,52.58,29.5534,52.5733,29.11ZM36.67,35.9135a7.0826,7.0826,0,1,1,7.0826-7.0826A7.0907,7.0907,0,0,1,36.67,35.9135Z"/>
                            <path fill="${colour}" d="M52.5733,29.11c0-9.3149-7.1338-16.8923-15.9034-16.8923a14.96,14.96,0,0,0-3.8645.5254c8.3948.4493,15.0989,7.8225,15.0989,16.849.0068.4436.0068,11.3036-12.812,30.9509a5.8543,5.8543,0,0,1-.5865.7972,2.8549,2.8549,0,0,0,2.0393.9534c1.8394,0,3.0941-2.0266,3.2162-2.2327C52.58,40.4135,52.58,29.5534,52.5733,29.11Z"/>
                          </g>
                          <g id="line">
                            <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M36.5451,62.2935a3.4628,3.4628,0,0,1-2.8174-1.9343C20.99,40.7228,20.7692,29.5793,20.7665,29.114c0-9.3191,7.1337-16.8964,15.9034-16.8964S52.5733,19.7949,52.5733,29.11c.0068.4436.0068,11.3036-12.812,30.9509C39.6392,60.2669,38.3845,62.2935,36.5451,62.2935Z"/>
                            <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M36.67,35.9135a7.0826,7.0826,0,1,1,7.0827-7.0826A7.0906,7.0906,0,0,1,36.67,35.9135Z"/>
                          </g>
                        </svg>
    `)}`;
    const customMarkerIcon = L.icon({
      popupAnchor: [-0, -0],
      iconSize: [32, 45],
      iconUrl: urlIcon,
    });
    return customMarkerIcon;
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(resetFilters({ page: 0, rows: 5 }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <title> Audits </title>
      </Helmet>
      {editModal && <AuditModal setOpenModal={setEditModal} sessionId={sessionId} />}
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h3" gutterBottom>
            Audits
          </Typography>
        </Stack>
        <AuditFilter
          endDateValue={dateTo}
          singleDateValue={dateSingle}
          startDateValue={dateFrom}
          ApplyFilter={() => dispatch(fetchListAudits(queryString))}
          selectedUser={userSelected}
          lastRun={lastRun}
          listUsers={listUsers}
          ResetFilters={ResetAudits}
          componetView="test"
          availableDates={datesAvailable}
        />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <AuditListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rowCountTotal}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rowCountTotal > 0 &&
                    filteredAudits?.map((row) => (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={1} marginLeft="10px">
                            <Avatar alt={row.template} src="/assets/images/avatars/approved.png" />
                            <Typography variant="subtitle2" noWrap style={{ textTransform: 'capitalize' }}>
                              {row.client?.data}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="tableNameLabel" noWrap>
                            {row.answered_by}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row.uploaded_on}</TableCell>
                        <TableCell align="left">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => {
                              setSessionId(row.session_id);
                              setEditModal(true);
                            }}
                          >
                            <Iconify icon={'carbon:folder-details-reference'} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}

                  {rowCountTotal === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={6} align="center">
                        {' '}
                        No Data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={rowCountTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Grid container mb={3} mt={3} className="d-flex justify-content-center">
            <MapContainer
              zoom={6}
              style={{ height: '650px', width: '90%' }}
              center={[-28.4792625, 24.6727135]}
              whenReady={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {filteredAudits?.map((audit) => {
                if (audit.latitude) {
                  return (
                    <Marker
                      position={[audit.latitude, audit.longitude]}
                      riseOnHover
                      interactive
                      key={audit.id}
                      icon={GetCustomMarker(audit.color)}
                    >
                      <Popup>
                        <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                          {audit.client?.data}
                        </Typography>
                        <ul>
                          <li>
                            <Typography variant="overline">Start time: </Typography>
                            <Typography variant="body">{audit.start_time}</Typography>
                          </li>
                          <li>
                            <Typography variant="overline">End time: </Typography>
                            <Typography variant="body">{audit.end_time}</Typography>
                          </li>
                          <li>
                            <Typography variant="overline">Handled by: </Typography>
                            <Typography variant="body" style={{ textTransform: 'capitalize' }}>
                              {audit.answered_by}
                            </Typography>
                          </li>
                        </ul>
                      </Popup>
                    </Marker>
                  );
                }
                return null;
              })}
              <Legend map={map} users={listUsers} selectedUser={userSelected} />
            </MapContainer>
          </Grid>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem disabled>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} disabled>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </div>
  );
};

export default AuditPageCopy;
