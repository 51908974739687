import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { CreateUser, GetListUsers } from '../../utils/APIFunctions';

export const fetchUsersList = createAsyncThunk('users/listUsers', async (token) => {
  const response = await GetListUsers(token);
  return response;
});

export const createNewUser = createAsyncThunk('users/addUser', async (receivedApi) => {
  const result = await CreateUser({ data: receivedApi.apiData, userToken: receivedApi.token });
  return result.data;
});
export const userSlice = createSlice({
  name: 'users',
  initialState: { entities: [], loading: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.loading = 'idle';
      })
      .addCase(fetchUsersList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.entities.push(action.payload);
        state.loading = 'success';
      });
  },
});

export default userSlice.reducer;

export const usersListSelector = createSelector(
  (state) => state.users,
  (users) => users.entities
);

export const usersStatusSelector = createSelector(
  (state) => state.users,
  (users) => users.loading
);
