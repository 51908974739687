import {
  Typography,
  Container,
  Card,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  FormLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import { useLoading } from '../Loader-Context';
import { createNewUser, usersStatusSelector } from '../redux/reducers/userSlice';
import { teamsListSelector } from '../redux/reducers/teamSlice';
import { authSelector } from '../redux/reducers/authSlice';

const pattern = /^\+(?:[0-9] ?){10,14}[0-9]$/;
const UserCreate = () => {
  const [selectedColor, setColor] = useColor('hex', '#121212');
  const teams = useSelector(teamsListSelector);
  const status = useSelector(usersStatusSelector);
  const authToken = useSelector(authSelector);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [active, setActive] = useState(false);
  const [staff, setStaff] = useState(false);
  const colorContainerRef = React.useRef();
  const { setLoading } = useLoading();
  setLoading(false);

  const SubmitUser = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const APIData = {
      username: data.get('userName'),
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
      email: data.get('email'),
      phone_number: data.get('contact'),
      team: selectedTeam,
      color: selectedColor.hex,
      is_staff: staff,
      is_active: active,
      organization: '',
    };
    if (APIData.phone_number.match(pattern)) {
      APIData.phone_number = APIData.phone_number.replace(/\s/g, '');
      dispatch(createNewUser({ apiData: APIData, token: authToken }));
      if (status === 'success') window.location.href = 'dashboard/user';
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>New User</title>
      </Helmet>
      <Container maxWidth="xl">
        <Card sx={{ padding: '20px 30px' }}>
          <form onSubmit={SubmitUser}>
            <Typography variant="h4">Personal Details:</Typography>
            <Grid container spacing={5} direction="row" alignItems="baseline" mb={4}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="first-name">First Name</InputLabel>
                  <Input id="first-name" name="firstName" autoComplete="off" />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="last-name">Last Name</InputLabel>
                  <Input id="last-name" required name="lastName" autoComplete="off" />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-name">Username</InputLabel>
                  <Input id="user-name" required name="userName" autoComplete="off" />
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="h4">Contact Details:</Typography>
            <Grid container spacing={5} direction="row" alignItems="baseline" mb={4}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="my-input">Email address</InputLabel>
                  <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    type="email"
                    required
                    name="email"
                    autoComplete="off"
                  />
                  <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="contact-input">Contact Number</InputLabel>
                  <Input
                    id="contact-input"
                    aria-describedby="contact-helper-text"
                    type="tel"
                    required
                    name="contact"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      if (e.target.value.match(pattern)) {
                        setError(false);
                      }
                    }}
                  />
                  {error ? (
                    <FormHelperText id="contact-helper-text" style={{ color: 'red' }}>
                      Enter a valid contact number, please include the country code (e.g. +27).
                    </FormHelperText>
                  ) : (
                    <FormHelperText id="contact-helper-text">
                      Please include the country code (e.g. +27).
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="h4">Admin Details:</Typography>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth ref={colorContainerRef}>
                  <FormLabel htmlFor="map-color">User Colour</FormLabel>
                  {colorContainerRef.current && (
                    <ColorPicker
                      width={colorContainerRef.current.clientWidth}
                      height={228}
                      color={selectedColor}
                      onChange={setColor}
                      hideHSV
                      dark
                      hideHEX
                      hideRGB
                      id="map-color"
                      aria-describedby="color-helper-text"
                    />
                  )}
                  <FormHelperText id="color-helper-text">Colour used for map markers.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">Team</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedTeam}
                    required
                    onChange={(e) => setSelectedTeam(e.target.value)}
                    label="Team"
                    name="team"
                  >
                    {/* <Input placeholder="Create a new team" fullWidth onBlur={(e)=>CreateNewTeam(e)} /> */}
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {teams ? (
                      teams.map((team) => (
                        <MenuItem value={team.id} key={team.id}>
                          {team.team_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No Available teams</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Stack direction="column" mt={2}>
                  <Typography variant="overline">User Status</Typography>
                  <FormControlLabel
                    value={active}
                    control={<Checkbox />}
                    label="Active"
                    required
                    labelPlacement="end"
                    onChange={(e) => setActive(e.target.checked)}
                  />
                  <FormControlLabel
                    value={staff}
                    control={<Checkbox />}
                    label="Dashboard User"
                    required
                    labelPlacement="end"
                    onChange={(e) => setStaff(e.target.checked)}
                  />
                </Stack>
                <Container maxWidth="xl" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} alignSelf="end">
                    Create
                  </Button>
                </Container>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </>
  );
};

export default UserCreate;
