import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { ListTeams } from '../../utils/APIFunctions';

export const fetchListTeams = createAsyncThunk('teams/listTeams', async (token) => {
  const response = await ListTeams(token);
  return response;
});

export const teamSlice = createSlice({
  name: 'teams',
  initialState: { entities: [], loading: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListTeams.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.loading = 'idle';
      })
      .addCase(fetchListTeams.pending, (state) => {
        state.loading = 'pending';
      });
  },
});

export default teamSlice.reducer;

export const teamsListSelector = createSelector(
  (state) => state.teams,
  (teams) => teams.entities
);
