export default function TablePagination() {
  return {
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginBottom: '2px',
        },
        displayedRows: {
          marginBottom: 0,
        },
        select: {
          paddingBottom: '4px',
        },
      },
    },
  };
}
