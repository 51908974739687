import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().auth;
      if (token) headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  keepUnusedDataFor: 60 * 30,
  tagTypes: [
    'SessionDetails',
    'Widgets',
    'TeamSummary',
    'UserDetails',
    'AuditsByID',
    'TmdDates',
    'TmdFbDetails'
  ],
  endpoints: () => ({}),
});
