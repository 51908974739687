import React from 'react';
import { useLoading } from '../../Loader-Context';
import './loader.css';

const LoadingApi = () => {
  const { loading } = useLoading();

  return loading ? (
    <div className="loader-container">
      {/* eslint-disable-next-line */}
      <span className="loader"></span>
    </div>
  ) : null;
};

export default LoadingApi;
