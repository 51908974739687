import React from 'react';
import PropTypes from 'prop-types';
import '../audit/Modal.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useUserDetailsQuery } from '../../../redux/services/userApi';

const UserModal = ({ setOpenModal, userId }) => {
  const { data: userDetails } = useUserDetailsQuery(userId);

  const convertDate = (date) => {
    const iso = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return `${iso.toLocaleDateString(undefined, options)}`;
  };

  return (
    <div className="modalBackground">
      <div className="user-modalContainer">
        <div className="modalHeader">
          <div className="titleCloseBtn">
            <IconButton
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <CloseIcon color="error" />
            </IconButton>
          </div>
          <div className="title">
            <h1>User Details</h1>
          </div>
        </div>
        <div className="body" style={{ display: 'flex', justifyContent: 'center' }}>
          {userDetails ? (
            <Grid container direction="column">
              <Grid container direction="column" mb={1}>
                <Grid item>
                  <Typography variant="h4">Personal Details:</Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    First Name:{' '}
                  </Typography>
                  <Typography variant="body" style={{ textTransform: 'capitalize' }}>
                    {userDetails?.first_name}
                  </Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Last Name:{' '}
                  </Typography>
                  <Typography variant="body" style={{ textTransform: 'capitalize' }}>
                    {userDetails?.last_name}
                  </Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Username:{' '}
                  </Typography>
                  <Typography variant="body" style={{ textTransform: 'capitalize' }}>
                    {userDetails?.username}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="column" mb={1}>
                <Grid item>
                  <Typography variant="h4">Contact Details:</Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    {' '}
                    Email:{' '}
                  </Typography>
                  <Typography variant="body">{userDetails?.email}</Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Contact Number:{' '}
                  </Typography>
                  <Typography variant="body">{userDetails?.phone_number}</Typography>
                </Grid>
              </Grid>

              <Grid container direction="column" mb={1}>
                <Grid item>
                  <Typography variant="h4">Admin Details:</Typography>
                </Grid>

                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Active User:{' '}
                  </Typography>
                  <Typography variant="body">{userDetails?.is_active ? 'Yes' : 'No'}</Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Dashboard User:{' '}
                  </Typography>
                  <Typography variant="body">{userDetails?.is_staff ? 'Yes' : 'No'}</Typography>
                </Grid>
                <Grid item direction="row" mb={2}>
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Team:{' '}
                  </Typography>
                  <Typography variant="body">{userDetails?.team}</Typography>
                </Grid>
                <Grid item direction="row">
                  <Typography variant="body" style={{ fontWeight: '700' }}>
                    Member since:{' '}
                  </Typography>
                  <Typography variant="body">{convertDate(userDetails?.date_joined)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress color="inherit" size={100} style={{ alignSelf: 'center' }} />
          )}
        </div>
      </div>
    </div>
  );
};
UserModal.propTypes = {
  setOpenModal: PropTypes.func,
  userId: PropTypes.number,
};
export default UserModal;
