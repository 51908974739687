import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { dashboardApi } from './rootApi';
import { rootReducer } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(dashboardApi.middleware),
});

export const persistor = persistStore(store);
